.dropdown {
	position: relative;
	display: block;
}

.dropdown-content {
	display: none;
	position: absolute;

	z-index: 1;
}

.dropdown:hover .dropdown-content {
	display: block;
}
.d_btn {
	border-bottom: 0.1rem solid black;
}
.card {
	padding-bottom: 15rem;
}
.action {
	background: #e6e0d1;
}
